<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="green darken-2" max-height="65">
          <v-toolbar-title> {{ rsData.RSCODE }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div>
          <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step
                color="green"
                step="1"
                class="pa-3 pl-md-16"
                :complete="rsData.ReturnedStatusID == 7"
                :rules="[() => rsData.ReturnedStatusID != 6]"
              >
              <span class="text-no-wrap">PA Approval</span>
              <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(rsData.ReturnedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="rsData.ReceivedStatusID == 7"
                :rules="[() => rsData.ReceivedStatusID != 6]"
                step="2"
                class="pa-3"
              >
                <span class="text-no-wrap">Receiver Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(rsData.ReceivedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="rsData.NotedStatusID == 7"
                :rules="[() => rsData.NotedStatusID != 6]"
                step="3"
                class="pa-3 pr-md-16"
              >
                <span class="text-no-wrap">Supply Head Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(rsData.NotedDate)}}</small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>
        <v-alert
          border="left"
          type="error"
          v-if="rsData.ReturnedStatusID == 6 || rsData.ReceivedStatusID == 6 || rsData.NotedStatusID == 6"
          prominent
          dense
          class="white--text amber accent-4"
        >
          <div v-if="rsData.ReturnedRemarks"> <strong>Person Accountable:</strong> {{ rsData.ReturnedRemarks }}</div>
          <div v-if="rsData.ReceivedRemarks"> <strong>Receiver:</strong> {{ rsData.ReceivedRemarks }}</div>
          <div v-if="rsData.NotedRemarks"> <strong>Supply Head:</strong> {{ rsData.NotedRemarks }}</div>
        </v-alert>
        <v-alert
          border="left"
          type="error"
          v-if="rsData.IsCancelled"
          prominent
          dense
          class="white--text amber accent-4"
        >
          <strong>CANCELLED</strong><br />
          <small>Date Cancelled: {{formatDateTime(rsData.DateCancelled)}}</small>
        </v-alert>
        <v-card-text style="max-height: 900px">
          <div>
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-h6"
                  >RETURN SLIP</v-subheader
                >
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-5">
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Entity Name:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>Davao del Norte State College</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Fund Cluster:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ rsData.FundCode }} - {{ rsData.SpecificFundSourceDescription }}</v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Supplier:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ rsData.CompanyName }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">IAR No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ rsData.IARCODE }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">PO No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ rsData.POCode }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Date Created:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ formatDate(rsData.DateCreated) }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">RIS No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ rsData.RISCODE }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">ICS/PAR No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ rsData.ICSPARCODE }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Returned By:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ rsData.ReturnBy }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Date Returned:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{formatDate(rsData.DateReturned)}}</v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Remarks:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="9">
              <v-subheader>
                <span v-if="rsData.Remarks">{{ rsData.Remarks }}</span>
                <span v-else><i>No Remarks</i></span>
                </v-subheader>
            </v-col>
          </v-row>
          </div>
          <v-row class="mt-3">
            <v-col lg="12" class="">
              <v-data-table
                :headers="headers"
                :items="itemList"
                item-key="RSItemID"
                class="elevation-1 mt-4"
                :expanded.sync="expanded1"
                :show-expand="!rsData.All"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >List Of Items</span
                    >
                    <v-spacer></v-spacer>
                    <span v-if="rsData.IsCancelled == 0">
                      <v-btn
                        color="header darken-2"
                        dark
                        outlined
                        elevation="2"
                        small
                        class="mr-2"
                        @click="approveRS(rsData)"
                        v-if="status == 0 || status == 1"
                      >
                        Approve
                      </v-btn>
                      <v-btn
                        color="red darken-2"
                        dark
                        outlined
                        elevation="2"
                        small
                        class="mr-2"
                        @click="pendingRS(rsData)"
                        v-if="status == 0"
                      >
                        Pending
                      </v-btn>
                    <v-btn
                      outlined
                      color="grey darken-2 mr-2"
                      dark
                      small
                      @click="editRS()"
                      v-if="$store.state.user.roleID == 6 || $store.state.user.roleID == 17"
                      class="mr-2"
                    >
                      <v-icon>mdi-pencil-outline</v-icon>
                      Edit RS
                    </v-btn>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      small
                      @click="print(rsData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print
                    </v-btn>
                    <v-btn
                      color="red darken-2"
                      class="white--text"
                      small
                      v-if="($store.state.user.roleID == 6 || $store.state.user.roleID == 17) && itemList.length > 0 && rsData.TransferID == 0"
                      @click="unpublish()"
                    >
                      Unpublish
                    </v-btn>
                    </span>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  {{ formatQty(item.Qty) }}
                </template>
                <template v-slot:[`item.Remarks`]="{ item }">
                  <span :class="item.RemarksID == 1 ? 'green--text' : item.RemarksID == 2 ? 'red--text' : ''">{{item.Remarks}}</span>
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="my-5">
                    <v-row v-for="(mr, index) in item.MRData" :key="index">
                      <v-col cols="12" class="px-2">
                        <v-row>
                          <v-col cols="12" sm="3">
                            <strong>Persons Accountable: </strong>
                          </v-col>
                          <v-col cols="12" sm="9">
                            {{getPANames(mr.UserID)}}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="4" class="px-2">
                         <strong>Brand: </strong> {{mr.Brand || 'Not applicable'}}
                      </v-col>
                      <v-col cols="12" sm="4" class="px-2">
                        <strong>Model: </strong> {{mr.Model || 'Not applicable'}}
                      </v-col>
                      <v-col cols="12" sm="4" class="px-2">
                        <strong>Serial No.: </strong> {{mr.SerialNo || 'Not applicable'}}
                      </v-col>
                      <v-col v-if="item.MRData.length > index + 1 "><v-divider></v-divider></v-col>
                    </v-row>
                    </div>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProcess" max-width="1000px" scrollable>
        <v-card>
          <v-card-title class="headline pt-5">
            <span>ICS/PAR Return</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogProcess = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text style="max-height: 900px">
          <v-form @submit.prevent ref="RTRFormRef">
          <v-container>
            <v-row wrap align="center" v-if="rsData.NotedStatusID != 7">
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="formData.Returner"
                    label="Returned By"
                    :rules="[formRules.required]"
                    outlined
                    dense
                    color="header"
                    :items="returners"
                    item-text="name"
                    item-value="UserID"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu
                    dense
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateReturned"
                    transition="scale-transition"
                    offset-y
                    class="mb-4"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateReturned"
                        :rules="[formRules.required]"
                        label="Date Returned"
                        readonly
                        outlined
                        color="header"
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateReturned"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="header" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        class="white--text"
                        color="header"
                        @click="$refs.menu1.save(formData.DateReturned)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                <v-textarea
                  dense
                  outlined
                  color="header"
                  v-model="formData.Remarks"
                  label="Remarks "
                ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
              <v-data-table
                :headers="headers2"
                :items="formData.Lists"
                item-key="RSItemID"
                class="elevation-1"
              >
              <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2">List Of Returned Items</span>
                  </v-toolbar>
              </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  {{ formatQty(item.Qty) }}
                </template>
                <template v-slot:[`item.allowableQty`]="{ item }">
                  {{ formatQty(item.allowableQty) }}
                </template>
                <template v-slot:[`item.editableQty`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.editableQty" large>
                    <span :style="parseFloat(item.allowableQty) < parseFloat(item.editableQty) || parseFloat(item.editableQty) <= 0 ? 'color:red' : 'color:black'">{{ formatQty(item.editableQty) }}</span>
                    <template v-slot:input>
                      <v-text-field v-model="item.editableQty" type="number" :min="1" :max="parseFloat(item.allowableQty)" :rules="[parseFloat(item.allowableQty) >= parseFloat(item.editableQty), parseFloat(item.editableQty) > 0]"> </v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.ReturnType`]="{ item }">
                  <v-autocomplete
                      dense
                      outlined
                      hide-details
                      v-model="item.ReturnTypeID"
                      :rules="[formRules.required]"
                      color="header"
                      item-text="description"
                      auto-select-first
                      item-value="id"
                      style="max-width: 170px"
                      :items="returnTypes">
                  </v-autocomplete>
                </template>
                <!-- <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" v-if="item.MRData.length > 0">
                    <div class="my-5">
                      <v-autocomplete
                        v-model="item.MRData_selected"
                        label="Serial Number"
                        :rules="[formRules.required, item.MRData_selected.length == item.editableQty]"
                        outlined
                        dense
                        chips
                        hide-selected
                        :menu-props="{closeOnContentClick: true}"
                        :counter="item.editableQty" 
                        auto-select-first
                        small-chips
                        deletable-chips
                        multiple
                        color="header"
                        :items="item.MRData"
                        :item-text="getItemText"
                        item-value="MRItemID"
                      ></v-autocomplete>
                    </div>
                  </td>
                </template> -->
              </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="dialogProcess = false">Cancel</v-btn>
            <v-btn color="blue darken-2" :loading="isLoading" class="white--text" @click="confirmReturn()"><v-icon>mdi-clipboard-arrow-left</v-icon> Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog v-model="dialogUnpublish" max-width="500px">
          <v-card>
            <v-card-title class="headline pt-5">Confirmation</v-card-title>
            <div class="mx-6">
              Are you sure you want to unpublish this Return Slip?
            </div>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogUnpublish = false">Cancel</v-btn>
              <v-btn color="red darken-2 white--text" @click="confirmUnpublish()"><v-icon>mdi-delete</v-icon> Unpublish</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    <v-dialog v-model="approveDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to approve this Return Slip?
            <br /><br />
            <div>RS #: <strong>{{approveData.RSCODE}}</strong></div>
            <div>Returned By: <strong>{{approveData.ReturnBy}}</strong></div>
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="approveDialog = false">Cancel</v-btn>
            <v-btn color="header darken-2 white--text" @click="confirmApproveRS()"><v-icon class="mr-1">mdi-check-circle</v-icon>Approve</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog v-model="pendingDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to pending this Return Slip?
            <br /><br />
            <div>RS #: <strong>{{pendingData.RSCODE}}</strong></div>
            <div>Returned By: <strong>{{pendingData.ReturnBy}}</strong></div>
            <br />
            <v-form ref="formRef">
            <v-textarea
              color="green"
                dense
                v-model="formData.Remarks"
                :rules="[formRules.required]"
                label="Remarks"
                counter
                outlined
                maxlength="500"
                ></v-textarea>
            </v-form>
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="pendingDialog = false">Cancel</v-btn>
            <v-btn color="red darken-2 white--text" @click="confirmPendingRS()"><v-icon class="mr-1">mdi-clock-outline</v-icon>Pending</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  props: {
    rsData: {},
  },
  data: () => ({
    dialog: false,
    users: [],
    dialogModel: false,
    PurposeDialog: false,
    supplier: [],
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    dialogIARVersion: false,
    link: "",
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", align:"end", value: "UnitCost", sortable: false },
      { text: "QTY", align:"center", value: "Qty", sortable: false },
      { text: "Amount", align:"end", value: "Amount", sortable: false },
      { text: "Remarks", align:"center", value: "Remarks", sortable: false },
    ],
    headers2: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        align: "center",
        sortable: false,
      },
      { text: "Unit Cost", align:"end", value: "UnitCost", sortable: false },
      { text: "Qty", align:"center", value: "Qty", sortable: false },
      { text: "Serviceable", align:"center", value: "ReturnType", sortable: false },
    ],
    expanded1: [],
    expanded2: [],
    singleExpand: true,
    formData: {
      Lists: [],
      Returner: null,
      DateReturned: null,
      Remarks: ''
    },
    itemList: [],
    itemList_filtered: [],
    tabs: null,
    itemList_RS: [],
    submitbtn: false,
    dispenseTypes: [],
    ICSDispenseType: null,
    DSDialog: false,
    DSType: null,
    editDescription: false,
    dialogConfirmSubmit: false,
    generatedICSID : [],
    switchICS: false,
    ICSAllUserID: null,
    switchItemList: true,
    dialogUnpublish: false,
    isLoading: false,
    dialogProcess: false,
    returners: [],
    returnTypes: [],
    status: null,
    approveDialog: false,
    pendingDialog: false,
    dialogRISType: false,
    approveData: [],
    pendingData: [],
  }),
  watch: {
    rsData: {
      handler(data) {
        if (data.RSID) {
          this.formData.Returner = data.Returner;
          this.formData.DateReturned = data.DateReturned;
          if(data.DispenseTypeID == 1){ 
            // this.formData.UserID = parseInt(data.UserID);
            this.getReturners([data.UserID]);
          }
          else if(data.DispenseTypeID == 2){
            let d = data.UserID.split(',')
            this.getReturners(d);
            // this.formData.UserID = d.map(function (x) { 
            //   return parseInt(x, 10); 
            // });
          }
          if(data.status_prop != null && typeof data.status_prop !== 'undefined'){
            this.status = data.status_prop;
          }
          this.getItems();
          this.dialogModel = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getReturnTypes();
  },
  methods: {
    print() {
      window.open(this.apiUrl + "/rs/report/" + this.rsData.RSID);
    },
    closeDialog() {
      this.formData.Lists = [];
      this.itemList = [];
      this.dialogModel = false;
      this.eventHub.$emit("closeViewRS", false);
    },
    getItems() {
      this.unpublishBtn = false;
      this.formData.Lists = [];
      let data = new FormData();
      let type = this.rsData.ICSID ? 1 : this.rsData.PARID ? 2 : '';
      data.append("RSID", this.rsData.RSID);
      data.append("type", type);
      this.axiosCall("/rs/getItems", "POST", data).then((res) => {
        this.itemList = res.data.data.result;
        this.itemList_RS = res.data.data.result;
        this.formData.Lists = res.data.data.result;
        this.getMRData(type);
        this.users = res.data.data.users;
      });
    },
    editRS(){
      this.dialogProcess = true;
    },
    unpublish(){
      this.dialogUnpublish = true;
    },
    getMRData(type){
      if(type == 1){
        this.itemList.forEach(item => {
        let data = new FormData();
        data.append("ICSItemRID", item.ICSItemRID);
        this.axiosCall("/mritem/ics/rs/getItems", "POST", data).then((res) => {
          if(res.status){
            if(res.data.data.length > 0){
              res.data.data.forEach(item2 => {
                item2.UserID = item2.UserID.split(",").map(Number)
                if(this.ICSDispenseType == 1){
                item2.UserID = item2.UserID[0]
                }
              });
              item.MRData = res.data.data;
            }
            else{
              let MRData = []
            for (let index = 0; index < item.Qty; index++) {
              MRData.push({
                Brand: null,
                Model: null,
                SerialNo: null,
                UserID: null
              });
            }
            item.MRData = MRData
          }
          }
      });
      });
      }
      else if(type == 2){
      this.itemList.forEach(item => {
        let data = new FormData();
        data.append("PARItemRID", item.PARItemRID);
        this.axiosCall("/mritem/par/rs/getItems", "POST", data).then((res) => {
          if(res.status){
            if(res.data.data.length > 0){
              res.data.data.forEach(item2 => {
                item2.UserID = item2.UserID.split(",").map(Number)
                if(this.PARDispenseType == 1){
                item2.UserID = item2.UserID[0]
                }
              });
              item.MRData = res.data.data;
            }
            else{
              let MRData = []
            for (let index = 0; index < item.Qty; index++) {
              MRData.push({
                Brand: null,
                Model: null,
                SerialNo: null,
                UserID: null
              });
            }
            item.MRData = MRData
          }
          }
      });
      });
      }
      
    },
    getPANames(data){
      if(data){
      let result = [];
      if(Array.isArray(data)){
        data.forEach(item1 => {
          this.users.forEach(item2 => {
            if(item1 == item2.UserID){
              result.push(item2.name)
            }
        });
        });
      }
      else{
        this.users.forEach(item => {
          if(data == item.UserID){
            result.push(item.name)
          }
        });
      }
      return result.join(', ')
      }
      else{
        return ''
      }
      
    },
    getReturners(users) {
      let data = new FormData();
        data.append("Users", JSON.stringify(users));
      this.axiosCall("/getReturners", "POST", data).then((res) => {
        this.returners = res.data.data;
        this.formData.Returner = this.returners[0].UserID
      });
    },
    getReturnTypes() {
      this.axiosCall("/getAllReturnType", "GET").then((res) => {
        this.returnTypes = res.data.data;
      });
    },
    confirmReturn(){
      if(this.$refs.RTRFormRef.validate() && this.formData.Lists.length > 0){
        this.isLoading = true;
        let data = new FormData();
        data.append("RSID", this.rsData.RSID);
        data.append("ICSPARID", this.rsData.ICSID ? this.rsData.ICSID : this.rsData.PARID);
        data.append("ICSPARType", this.rsData.ICSID ? 1 : this.rsData.PARID ? 2 : 0);
        data.append("UserID", this.formData.Returner);
        data.append("DateReturned", this.formData.DateReturned);
        data.append("List", JSON.stringify(this.formData.Lists));
        data.append("Remarks", this.formData.Remarks ? this.formData.Remarks : '');
        this.axiosCall("/rs/edit", "POST", data).then((res) => {
          if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message = "Return Slip successfully updated.";
            this.dialogProcess = false;
            this.getItems();
          }
          else{
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message = "Something went wrong!";
          }
          this.isLoading = false;
          });
      }
      else{
        this.fadeAwayMessage.show = true;
        this.fadeAwayMessage.type = "error";
        this.fadeAwayMessage.header = "System Message";
        this.fadeAwayMessage.message = "Unable to proceed. Please check the details before submitting!";
      }
    },
    confirmUnpublish(){
      let data = new FormData();
      data.append("RSID", this.rsData.RSID);
      this.axiosCall("/rs/unpublish", "POST", data).then((res) => {
        if(res.data.status){
        this.closeDialog();
          this.dialogUnpublish = false;
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "Return Slip was successfully unpublished";
          this.fadeAwayMessage.header = "System Message";
        }
        else{
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = "Something went wrong!";
          this.fadeAwayMessage.header = "System Message";
        }
      });
    },
    approveRS(data){
      this.approveData = data;
      this.approveDialog = true;
    },
    pendingRS(data){
      this.pendingData = data;
      this.pendingDialog = true;
    },
    confirmApproveRS(){
      let data = new FormData();
      data.append("RSID", this.approveData.RSID);
      data.append("Admin", 1);
      this.axiosCall("/rs/approve", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "RS was successfully approved.";
            this.fadeAwayMessage.header = "System Message";
            this.approveDialog = false;
            this.approveData = [];
            this.status = null;
            this.closeDialog()
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
    },
    confirmPendingRS(){
      if(this.$refs.formRef.validate()){
      let data = new FormData();
      data.append("RSID", this.pendingData.RSID);
      data.append("Remarks", this.formData.Remarks);
      data.append("Admin", 1);
      this.axiosCall("/rs/pending", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "RS was successfully in pending status.";
            this.fadeAwayMessage.header = "System Message";
            this.pendingDialog = false;
            this.pendingData = [];
            this.status = 1;
            this.closeDialog()
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
      }
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>